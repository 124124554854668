<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.user" placeholder="请输入会员" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="name"
          label="充值用户"
          align="center"
          width="250">
        <template slot-scope="scope">
          <div v-if="scope.row.name" class="member-box">
            <img :src="scope.row.avatar" />
            <div>
              <div>{{scope.row.name}}（{{scope.row.user_id}}）</div>
              <div>{{scope.row.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="amount"
          label="充值金额"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="payment_sn"
          label="交易单号"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="trade_sn"
          label="支付单号"
          align="center"
          width="250">
      </el-table-column>
      <el-table-column
          prop="pay_time"
          label="支付时间"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="下单时间"
          align="center"
          width="200">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      dateRange: ['',''],
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        user: ''
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        user: that.search.user,
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      this.$api.order.orderTradeRecharge(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
